<template>
	<div class="row table-historiales">
		<div class="col-12">
			<tabla-general :data="dataTable" alto="calc(100vh - 268px)" class="cr-pointer"
				class-header="text-general f-16 f-500" sinDatos="Sin registro de historial">
				<template slot="cabecera-izquierda">
					<div class="col-auto f-18 text-general f-600">
						<p class="pl-3">Historial ({{ dataTable.length }})</p>
					</div>
				</template>
				<el-table-column label="Id" sortable width="100">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.id_admin_pedido }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Fecha" min-width="100">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.entrega_fecha | helper-fecha('DD MMM YYYY') }} </p>
					</template>
				</el-table-column>
				<el-table-column label="Destinatario" min-width="120">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.destinatario }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Dirección" min-width="200">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.direccion }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Cedis" min-width="100">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.cedis }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Transportadora" min-width="150">
					<template slot-scope="{row}">
						<p class="f-16 text-black2">{{ row.transportadora }}</p>
					</template>
				</el-table-column>
				<el-table-column label="No. de guía" min-width="150">
					<template slot-scope="{row}">
						<div class="">
							<div class="bg-general text-white wf-100px h-32px d-middle-center f-15 br-5" @click="openModalguias(row.guias)">
								Ver
							</div>
						</div>
					</template>
				</el-table-column>
			</tabla-general>
		</div>
		<modal-guias ref="refOpenModalGuias" />
	</div>
</template>

<script>
import Servicio from "~/services/serviciosPaqueteria";
export default {
	components: {
        modalGuias: () => import('./partials/modalGuiasEnvio.vue'),
    },
	data() {
		return {
			idServicio: parseInt(this.$route.params.id),
			dataTable: [],
		}
	},
	mounted() {
		this.getList()
	},
	methods: {
		redirect(route) {
			window.open(route, '_blank');
		},
		async getList() {
			try {
				const { data } = await Servicio.getEnvios(this.idServicio, { estados: [4, 25, 26, 27, 31, 23, 33, 34] })
				this.dataTable = data.data
			} catch (e) {
				return this.error_catch(e)
			}
		},
		openModalguias(items){
			this.$refs.refOpenModalGuias.toggle(items)
		},
	},

}
</script>

<style lang="scss" scoped></style>